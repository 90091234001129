<template>
  <i>
    <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
      <circle cx="20" cy="20" r="20" fill="#44AD4E"/>
      <path d="M20 9.14583C16.13 9.14583 13 12.504 13 16.6562C13 22.289 20 30.604 20 30.604C20 30.604 27 22.289 27 16.6562C27 12.504 23.87 9.14583 20 9.14583ZM15 16.6562C15 13.695 17.24 11.2917 20 11.2917C22.76 11.2917 25 13.695 25 16.6562C25 19.7462 22.12 24.3704 20 27.2566C17.92 24.3919 15 19.714 15 16.6562Z" fill="white"/>
      <path d="M20 19.3385C21.3807 19.3385 22.5 18.1376 22.5 16.6562C22.5 15.1748 21.3807 13.9739 20 13.9739C18.6193 13.9739 17.5 15.1748 17.5 16.6562C17.5 18.1376 18.6193 19.3385 20 19.3385Z" fill="white"/>
    </svg>
  </i>
</template>

<script>
export default {
  name: 'LocationIcon'
}
</script>
