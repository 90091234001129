<template>
  <main class="main">
    <section class="welcome welcome__contacts">
      <div class="container">
        <div class="welcome__inner">
          <h1 class="welcome__title welcome__contacts__title">
            Контакты
          </h1>
        </div>
      </div>
    </section>

    <section class="section container" v-if="contactsData">
      <div class="form__container contacts__container">
        <div class="contacts">
          <div class="contacts__item" v-if="contactsData.address">
            <div class="contacts__item__icon">
              <LocationIcon/>
            </div>
            <div class="contacts__item__info">
              <div class="contacts__item__title">
                Адрес
              </div>
              <div class="contacts__item__content">
                {{ contactsData.address }}
              </div>
              <div class="contacts__item__subtext">
                {{ contactsData.work_time }}
              </div>
            </div>
          </div>

          <div class="contacts__item" v-if="contactsData.number">
            <div class="contacts__item__icon">
              <PhoneIcon/>
            </div>
            <div class="contacts__item__info">
              <div class="contacts__item__title">
                Номер телефона
              </div>
              <div class="contacts__item__content">
                {{ contactsData.number }}
              </div>
            </div>
          </div>

          <div class="contacts__item" v-if="contactsData.email">
            <div class="contacts__item__icon">
              <MessageIcon/>
            </div>
            <div class="contacts__item__info">
              <div class="contacts__item__title">
                Почтовый адрес
              </div>
              <div class="contacts__item__content">
                {{ contactsData.email }}
              </div>
            </div>
          </div>
        </div>

        <h3 v-if="isAnyRequisites" class="requisites__title">
          Реквизиты
        </h3>

        <div class="contacts" v-if="isAnyRequisites">
          <div class="contacts__item" v-if="contactsData.company_name">
            <div class="contacts__item__icon">
              <div class="contacts__item__icon__bg">
                <img src="public/images/logo.png" alt="">
              </div>
            </div>
            <div class="contacts__item__info">
              <div class="contacts__item__title">
                Название компании
              </div>
              <div class="contacts__item__content">
                {{ contactsData.company_name }}
              </div>
            </div>
          </div>

          <div class="contacts__item" v-if="contactsData.inn">
            <div class="contacts__item__icon">
              <div class="contacts__item__icon__bg">
                <img src="public/images/logo.png" alt="">
              </div>
            </div>
            <div class="contacts__item__info">
              <div class="contacts__item__title">
                ИНН
              </div>
              <div class="contacts__item__content">
                {{ contactsData.inn }}
              </div>
            </div>
          </div>

          <div class="contacts__item" v-if="contactsData.kpp">
            <div class="contacts__item__icon">
              <div class="contacts__item__icon__bg">
                <img src="public/images/logo.png" alt="">
              </div>
            </div>
            <div class="contacts__item__info">
              <div class="contacts__item__title">
                КПП
              </div>
              <div class="contacts__item__content">
                {{ contactsData.kpp }}
              </div>
            </div>
          </div>

          <div class="contacts__item" v-if="contactsData.ogrn">
            <div class="contacts__item__icon">
              <div class="contacts__item__icon__bg">
                <img src="public/images/logo.png" alt="">
              </div>
            </div>
            <div class="contacts__item__info">
              <div class="contacts__item__title">
                ОГРН
              </div>
              <div class="contacts__item__content">
                {{ contactsData.ogrn }}
              </div>
            </div>
          </div>

          <div class="contacts__item" v-if="contactsData.bank_name">
            <div class="contacts__item__icon">
              <div class="contacts__item__icon__bg">
                <img src="public/images/logo.png" alt="">
              </div>
            </div>
            <div class="contacts__item__info">
              <div class="contacts__item__title">
                Название банка
              </div>
              <div class="contacts__item__content">
                {{ contactsData.bank_name }}
              </div>
            </div>
          </div>

          <div class="contacts__item" v-if="contactsData.account">
            <div class="contacts__item__icon">
              <div class="contacts__item__icon__bg">
                <img src="public/images/logo.png" alt="">
              </div>
            </div>
            <div class="contacts__item__info">
              <div class="contacts__item__title">
                Номер банковского счета
              </div>
              <div class="contacts__item__content">
                {{ contactsData.account }}
              </div>
            </div>
          </div>

          <div class="contacts__item" v-if="contactsData.bik">
            <div class="contacts__item__icon">
              <div class="contacts__item__icon__bg">
                <img src="public/images/logo.png" alt="">
              </div>
            </div>
            <div class="contacts__item__info">
              <div class="contacts__item__title">
                БИК
              </div>
              <div class="contacts__item__content">
                {{ contactsData.bik }}
              </div>
            </div>
          </div>

          <div class="contacts__item" v-if="contactsData.corr_account">
            <div class="contacts__item__icon">
              <div class="contacts__item__icon__bg">
                <img src="public/images/logo.png" alt="">
              </div>
            </div>
            <div class="contacts__item__info">
              <div class="contacts__item__title">
                Корреспондентский счет
              </div>
              <div class="contacts__item__content">
                {{ contactsData.corr_account }}
              </div>
            </div>
          </div>

          <div class="contacts__item" v-if="contactsData.director_name">
            <div class="contacts__item__icon">
              <div class="contacts__item__icon__bg">
                <img src="public/images/logo.png" alt="">
              </div>
            </div>
            <div class="contacts__item__info">
              <div class="contacts__item__title">
                Директор
              </div>
              <div class="contacts__item__content">
                {{ contactsData.director_name }}
              </div>
            </div>
          </div>
        </div>

        <iframe class="contacts__map"
                src="https://yandex.ru/map-widget/v1/?um=constructor%3A9307dbc27f275bf0a190fd10a4e214e5a86bd1cb2304dbdaff9556a5cffa2bc0&amp;source=constructor"
                width="1046" height="490" frameborder="0"></iframe>
      </div>
    </section>
  </main>
</template>

<script>
import LocationIcon from 'components/svg/Location.vue'
import PhoneIcon from 'components/svg/ContactPhone.vue'
import MessageIcon from 'components/svg/ContactMessage.vue'

export default {
  async asyncData ({ store }) {
    await store.dispatch('GET_CONTACTS_PAGE')
  },
  name: 'contacts',
  computed: {
    contactsData () {
      return this.$store.state.contacts_page?.contacts
    },
    nav () {
      return this.$store.state.navigators.find(item => item.link === this.$route.path)
    },
    isAnyRequisites () {
      return this.contactsData.company_name || this.contactsData.inn || this.contactsData.kpp || this.contactsData.ogrn || this.contactsData.bank_name || this.contactsData.account || this.contactsData.bik || this.contactsData.corr_account || this.contactsData.director_name
    }
  },
  components: {
    LocationIcon,
    PhoneIcon,
    MessageIcon
  },
  metaInfo () {
    return this.$seo(
      'common',
      this.nav.meta_title,
      this.nav.meta_keywords,
      this.nav.meta_description,
      'Mavadda',
      '',
      'Mavadda'
    )
  },
  jsonld () {
    return {
      '@context': 'https://schema.org',
      '@type': 'Organization'
    }
  }
}
</script>
