<template>
  <i>
    <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
      <circle cx="20" cy="20" r="20" fill="#44AD4E"/>
      <path d="M23.5 8.07291H15.5C14.12 8.07291 13 9.27457 13 10.7552V28.9947C13 30.4753 14.12 31.6769 15.5 31.6769H23.5C24.88 31.6769 26 30.4753 26 28.9947V10.7552C26 9.27457 24.88 8.07291 23.5 8.07291ZM19.5 30.604C18.67 30.604 18 29.8852 18 28.9947C18 28.1042 18.67 27.3853 19.5 27.3853C20.33 27.3853 21 28.1042 21 28.9947C21 29.8852 20.33 30.604 19.5 30.604ZM24 26.3124H15V11.2916H24V26.3124Z" fill="white"/>
    </svg>
  </i>
</template>

<script>
export default {
  name: 'ContactPhoneIcon'
}
</script>
