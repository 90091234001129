<template>
  <i>
    <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
      <circle cx="20" cy="20" r="20" fill="#44AD4E"/>
      <path d="M28 11.2916H12C10.9 11.2916 10.01 12.2573 10.01 13.4375L10 26.3124C10 27.4926 10.9 28.4582 12 28.4582H28C29.1 28.4582 30 27.4926 30 26.3124V13.4375C30 12.2573 29.1 11.2916 28 11.2916ZM28 26.3124H12V15.5833L20 20.9478L28 15.5833V26.3124ZM20 18.802L12 13.4375H28L20 18.802Z" fill="white"/>
    </svg>
  </i>
</template>

<script>
export default {
  name: 'ContactMessageIcon'
}
</script>
